import {    
    Routes,
    Route,
  } from "react-router-dom";
import App from '../../pages/dashboard/App';
import Login from '../../pages/login/Login';
import Profile from '../../pages/profile/Profile';

export const AppRoutes = () => {    
    return <>           
        <Routes>          
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<App />} />
            <Route path="/profile" element={<Profile />} />
        </Routes >
    </>
}