import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from './components/routes/route';
import { MainProvider } from './providers/context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>            
    <MainProvider>
      <Router>              
        <AppRoutes />     
      </Router>
    </MainProvider>
  </React.StrictMode>
);

reportWebVitals();
