const labels = {
    k: 'Mil',    
    m: 'M.',    
    b: 'Bi'
}

export const formatMoneyCards = (num: number | any) => {    
    num = num.toString()
    switch (true) {
        case num.length >= 4 && num.length <= 6:            
            return `R$ ${num.substring(0, num.length - 3)} ${labels.k}`
        case num.length >= 7 && num.length <= 9:
            return `R$ ${num.substring(0, num.length - 6)} ${labels.m}`
        case num.length > 9:
            return `R$ ${num.substring(0, num.length - 9)} ${labels.b}`
    }
}

export const maskMoney = (val: string | any) => {
    val.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });    
    return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
}

export const formatPrecatoryLabel = (a: any | Array<number>, i: number, qtd: number) => {        
    switch (i) {
        case qtd:        
            return `Mais de ${formatMoneyCards(a)}`
        case 1:
            return `Até ${formatMoneyCards(a[1])}`
        default:
            return `De ${formatMoneyCards(a[0])} até ${formatMoneyCards(a[1])}`
    }    
}