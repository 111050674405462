import { useEffect, useState } from 'react';
// import Button from '@mui/material/Button';
import axios from 'axios';
import './App.css';

import { styled } from '@mui/material/styles';
//import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
//import AddIcon from '@mui/icons-material/Add';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
//import { WidthFull } from '@mui/icons-material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Badge, CardActionArea, CardActions } from '@mui/material';

import ResponsiveAppBar from '../../components/menu/menu';
import Container from '@mui/material/Container';
import SideDrawer from '../../components/menu/sideNav';

// import * as bt from 'react-bootstrap';

// import img1 from '../../assets/pexels-kris-møklebust-13935764.jpg';
// import img2 from '../../assets/pexels-guillaume-meurice-13960602.jpg';
import img3 from '../../assets/pexels-dmytro-kormylets-12192456.jpg';
import FullModal from '../../components/modal/fullModal';
import { getOutOfAccessPage, hostApi } from '../../components/helpers/url';
import { CardResult } from '../../dto/precatorioCardsRes';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { formatPrecatoryLabel, maskMoney } from '../../components/helpers/formatCardValues';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function App() {      
  const[cardResults, setCardResults] = useState<CardResult[]>();
  const envUrl = hostApi();  

  const getCards = async () => {
    return await axios.get<Array<CardResult>>(`${envUrl}/api/display-results`)
      .then((res) => setCardResults(res?.data))
  }

  const Variants = () => {
    return Array.from(Array(5)).map((_, i) => (
      <Grid xs={12} sm={4} md={4} key={`card_${i}`}>
        <Item style={{background: 'transparent'}}>
          <Stack spacing={1} key={`skeleton_${i}`}>
            <Skeleton
              sx={{ bgcolor: 'grey.900' }}
              variant="rectangular"              
              height={188}
            />
          </Stack>
        </Item>
      </Grid>
    ));
  }

  const CardsPrecatorio = () => {
    return <>
      { cardResults?.map((a, i) => {  
        return <Grid xs={12} sm={4} md={4} key={`card_${i}`}>
          <Item style={{background: 'transparent'}}>
            <Card sx={{ maxWidth: '100%' }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="50"
                  image={img3}
                  alt="Card precatório"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">                    
                    { formatPrecatoryLabel(a.precatory, (i + 1), cardResults.length) }
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    RS: {maskMoney(a.price)}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <div className="card-btn-info">                            
                  <FullModal name={formatPrecatoryLabel(a.precatory, (i + 1), cardResults.length)} cardId={(i + 1)} />
                  <span>Qtd: 
                    <Badge badgeContent={a.total > 0 ? a.total : '0'} color="secondary" />
                  </span>
                </div>
              </CardActions>
            </Card>
          </Item>
        </Grid>
      }) }
    </>
  }

  const dashboardPage = () => {
    return <>
      <ResponsiveAppBar />            
      <div className="App">    
        <header className="App-header" style={{paddingTop: '6.8rem'}}>
                    
          <DocumentScannerIcon sx={{ display: { xs: 'flex', md: '12' }, mr: 1, color: 'cyan' }} />

          <span style={{ maxWidth: '800px', paddingTop: '1rem', paddingBottom: '3rem', fontSize: '1.2rem' }}>          
            Confira abaixo toda nosso base de precatório, para consultar por valores selecione o valor desejado do precatório. 
          </span>
          
          <Container maxWidth="lg" style={{ display: 'flex' }}>            
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                { cardResults !== undefined ? CardsPrecatorio() : Variants() }
              </Grid>
            </Box>
          </Container>

          <div style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
            <span style={{ fontSize: '1.3rem', paddingRight: '1rem', color: 'cyan', letterSpacing: '1.6px' }}><b>Total:</b> </span>
            <span>
              R$: { cardResults !== undefined 
              ? maskMoney(cardResults?.filter(s => s.price)
                .map(a=>a.price)
                .reduce((partialSum, a) => partialSum + a, 0))
              : 0 }              
            </span>
          </div>
                  
          <SideDrawer side="left" />          
        </header>        
      </div>
    </>
  }

  useEffect(() => {
    const userStorage = localStorage.getItem("user");        
    if (!userStorage)
      getOutOfAccessPage()
    if (cardResults === undefined)
      getCards()
  })

  return (     
    <div>
      { dashboardPage()}      
    </div>
  );
}

export default App;
