import React from 'react';
import './Login.css'
import logo from '../../assets/logo_blue.png'
import axios from "axios";
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import { useState } from 'react';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import { SnackbarOrigin } from '@mui/material/Snackbar';
//import { redirect, RedirectFunction } from 'react-router-dom';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { State, LoginResponse } from '../../dto/loginInterface';
import { useMain } from '../../providers/context';
import { hostApi } from '../../components/helpers/url';


const Login = () => { 
    const { enqueueSnackbar } = useSnackbar()    
    const [values, setValues] = useState<State>({
        email: '',
        password: '',
        showPassword: false,
    });

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const handleToggle = () => setOpen(!open);
        
    const envUrl = hostApi();

    const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };    

    const Alert = (variant: VariantType, msg: string) => {        
        const direction: SnackbarOrigin = {
            vertical: 'bottom', 
            horizontal: 'right'
        }        
        enqueueSnackbar( msg !== undefined ? msg : 'Conversão realizada com sucesso!', { 
            variant, anchorOrigin: direction 
        }); 
    }    

    const { logged, setUser, setLogged } = useMain();
    
    // const handleLogout = () => {        
    //     localStorage.removeItem('user');
    //     setUser({name: 'Stranger', color: '#0000ff'});
    //     setLogged(false);
    // };

    const login = () => {
        if (values.email === undefined || values.email === '')
            Alert('warning', 'E-mail não informado!')
        if (values.password === undefined || values.password === '')
            Alert('warning', 'Senha não informado!')
        if (values.password !== '' && values.password.length <= 4)
            Alert('warning', 'Senha invalida!')

        if (values.email !== '' && values.password.length > 4) {            
            
            const options = {
                method: 'POST',
                url: `${envUrl}/api/user/auth`,
                headers: {'Content-Type': 'application/json'},
                data: {user: `${values.email}`, pw: `${values.password}`}
            };

            handleToggle()

            axios.request<LoginResponse>(options).then((response) => {
                handleClose()
                if (response?.data.code === 200) {
                    Alert('success', `Autenticação ${response.data.status}`)
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    setUser(response.data.data);
                    setLogged(true);
                    setTimeout(() => window.location.href = 'dashboard' , 2500)
                } else
                    Alert('error', 'Erro de login')

            }).catch((error) => {
                handleClose()
                console.error(error);
            });            
        }            
    }

    const getOutOfLogin = () => {
        return window.location.href = 'dashboard'
    }

    const LoginPage = () => {
        return <>
            <section className="App-body">
                <div className='form'>
                    <div className="logo">
                        <img src={logo} alt="special situation logo" />
                    </div>
                    <form>
                        {/* <p>{user.name} status: {`${logged}`}</p> */}
                        <h3>Fazer Login na sua conta</h3>
                        <div>
                        <TextField sx={{ m: 1, width: '20ch' }}                            
                            id="filled-multiline-flexible"
                            label="E-mail"
                            multiline
                            maxRows={4}
                            value={values.email}
                            onChange={handleChange('email')}
                            variant="filled"
                            style={{}}
                            />
                        </div>
                        <div>
                        <FormControl sx={{ m: 1, width: '20ch' }} variant="filled">
                            <InputLabel htmlFor="filled-adornment-password">Senha</InputLabel>
                            <FilledInput
                                id="filled-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>                                              
                        </div>                        
                        <FormControl sx={{ m: 1, width: '20ch' }} variant="filled">
                            <Button variant="contained" className="progress btn-login" size="large"
                                onClick={()=>login()}
                            >Entrar</Button>                        
                        </FormControl>
                        <div>
                            <span>Use seu e-mail e senha!</span>
                        </div>
                    </form>                        
                </div>
            </section>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                //onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    }

    return (
        <div className="App"> 
            { logged ? getOutOfLogin() : LoginPage() }
        </div>
    )
}

export default function IntegrationNotistack() {
    return (
      <SnackbarProvider maxSnack={3}>
        <Login />
      </SnackbarProvider>
    );
}