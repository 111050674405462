import { Avatar } from "@mui/material";
import { useEffect } from "react";
import { getOutOfAccessPage } from "../../components/helpers/url";
import ResponsiveAppBar from "../../components/menu/menu";
import { useMain } from "../../providers/context";

const Profile = () => {
    const { user } = useMain();

    useEffect(() => {
        const userStorage = localStorage.getItem("user");        
        if (!userStorage)
            getOutOfAccessPage()
    })

    return <>
        <ResponsiveAppBar />            
        <div className="App">    
            <header className="App-header" style={{paddingTop: '6.8rem'}}>
                <Avatar alt={user.name} src={user.image !== undefined ? user.image : null} sx={{ width: 164, height: 164 }} style={{marginBottom: '2rem'}} />
                <h2>{user.name}</h2>
                <h4>{user.email}</h4>
                <h4>{user.mobile}</h4>
            </header>
        </div>
    </>
}

export default Profile