import { useMemo, useState } from 'react';
import { Button, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SortIcon from '@mui/icons-material/Sort';
import Modal from 'react-bootstrap/Modal';
import '../../assets/css-bt5/bootstrap.min.css';
import DataTable, { TableColumn } from 'react-data-table-component';
import { TableResultDataResponse, TableResultRequest, TableResultResponse } from '../../dto/precatoryTebleResult';
import axios from 'axios';
import { hostApi } from '../helpers/url';
import { maskMoney } from '../helpers/formatCardValues';

const columnsApi: TableColumn<TableResultDataResponse>[] = [
  // {
  //   name: "Id",
  //   selector: row => row.id,
  //   sortable: true
  // },
  {
    name: "Ação",
    selector:  row => row.acao,
    sortable: true
  },
  {
    name: "Credor",
    selector:  row => row.credor,
    sortable: true
  },
  {
    name: "Devedor",
    selector:  row => row.devedor,
    sortable: true
  },
  {
    name: "Valor Total",
    selector:  row => row.valor_total,
    format: row => <span style={
      {
        backgroundColor: '#0fb9b1',
        padding: '.4rem .8rem',
        color: '#fff',
        borderRadius: '50px',
        fontSize: '600',
        letterSpacing: '1px',
      }
    }><strong>RS {`${maskMoney(row.valor_total)}`}</strong></span>,
    sortable: true
  },
  {
    name: "Tibunal",
    selector:  row => row.tribunal,
    sortable: true,
    right: true
  }
];

export default function FullModal(props: {name: string, cardId: number | any}) {
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState<string | any>(values[0]);
  const [show, setShow] = useState<boolean>(false);
  const [modalName, setModalName] = useState<string>('')
  const [tableResultData, setTableResultData] = useState<TableResultResponse>()
  const [cardId, setCardId] = useState<number>(0)

  const envUrl = hostApi(); 

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShow(true);

    if (cardId !== props.cardId) {
      setCardId(props.cardId)
      getData(props.cardId)
    }
  }

  const getData = async (key: any) => {        
    let payload: TableResultRequest = {
        start: 0,
        length: 20,
        text: '',
        draw: 'null'
    };

    return await axios.post<TableResultResponse>(`${envUrl}/api/table-results?key=${key}`, payload)
      .then((res) => {            
        console.log(res?.data)
        setTableResultData(res?.data)
      }) 
  }

  useMemo(() => setModalName('📋 Lista de precatórios'), [])

  return (
    <>        
        <Button size="small" color="primary" onClick={() => handleShow(true)} >
            <AddIcon /> ver mais
        </Button>
        <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{modalName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Paper>
                {tableResultData !== undefined ?
                <DataTable
                  title={`${props.name}`}
                  columns={columnsApi}
                  data={tableResultData.data}
                  defaultSortFieldId="title"
                  sortIcon={<SortIcon />}
                  pagination
                  selectableRows
		              highlightOnHover
		              pointerOnHover
                />
                : null }
              </Paper>
            </Modal.Body>
        </Modal>
    </>
  );
}

//render(<Example />);