import React, { useEffect, useState } from 'react'
import { LoginResponseData } from '../dto/loginInterface';

export const MainContext = React.createContext({});

export const MainProvider = (props: any) => {
    const [ logged, setLogged ] = useState<boolean>(false)
    const [ user, setUser ] = useState<LoginResponseData>({        
        idx: 0,
        id: '',
        name: '',
        email: '',
        token: '',
        image: '',
        mobile: ''
    })    

    useEffect(()=>{
        const userStorage = localStorage.getItem("user");        
        if (userStorage) {
            setUser(JSON.parse(userStorage));
            setLogged(true);
        } else {
            setUser({...user});
            setLogged(false);
        }
    }, [setUser, setLogged, user])

    return (
        <MainContext.Provider value={{ 
            user, setUser, 
            logged, setLogged            
        }} >
            {props.children}
        </MainContext.Provider>
    )
}

export const useMain = () => React.useContext<any>(MainContext); // To do, type it!